<template lang="pug">
  v-container#config-price(fluid='' tag='section')
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Recordatorios de pacientes
          v-btn(icon="" dark="" @click="$emit('exitPatientReminder')" style="position: absolute; top: 0px; right: 10px;")
            v-icon.primary.rounded mdi-close
    v-card
      v-row
        v-col(cols='12' lg='6')
          h3(style='text-align: center;') Cumpleaños de pacientes
          v-row(style='display:flex; justify-content:center;')
            v-col(cols='12' style='padding-left:50px; padding-right:50px;')
              p
                small En la siguiente tabla se muestran los pacientes que cumplen años hoy, seleccione a los que le gustaría enviar un correo de recordatorio de control y presione ENVIAR CORREOS. También puede personalizar su mensaje a continuación.
              v-textarea(style='margin-top:20px;' outlined name="input-7-4" label="PERSONALIZA EL MENSAJE DE CUMPLEAÑOS" :value='birthMessage' v-model='birthMessage')
              v-row(style='display:flex; justify-content:center; margin-top:10px;')
                v-btn(color='primary' @click='saveBirthMessag')
                  | GUARDAR MENSAJE PERSONALIZADO
              v-text-field(v-model="searchBirth" append-icon="mdi-search" label= 'Buscar cumpleañero' hide-details="" clearable name='searchBar' type='text')
              v-row(style='margin-top:15px;')
                v-data-table.mx-2#tablaDatos(v-model="selectedToBirth" :headers='birthHeaders' :items="showBirthArr" single-select=false item-key="names" show-select='' :search="searchBirth" dense fixed-header :sort-by="orderBy" calculate-widths :footer-props="{\'items-per-page-text':'Pacientes por página'\}")
                  v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                    | Sin resultados
                  v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                    | Hoy no hay cumpleañeros registrados
                  v-flex(xs12='', sm10='', offset-sm1='')
                v-row(style='display:flex; justify-content:center; margin-top:10px;')
                  v-btn(color='primary' @click='sendBirthEmails' :loading="loadingSendBirthEmails")
                    | ENVIAR CORREOS
        v-col(cols='12' lg='6')
          h3(style='text-align: center;')  Recordatorio de control periódico
          v-row(style='display:flex; justify-content:center;')
            v-col(cols='12' style='padding-left:50px; padding-right:50px;')
              p
                small En la siguiente tabla se muestran los pacientes que no han registrado cambios hace 6 meses o más, seleccione a los que le gustaría enviar un correo de recordatorio de control y presione ENVIAR CORREOS. También puede personalizar su mensaje a continuación.
              v-textarea(style='margin-top:20px;' outlined name="input-7-4" label="PERSONALIZA EL MENSAJE DE RECORDATORIO" :value='remindMessage' v-model='remindMessage')
              v-row(style='display:flex; justify-content:center; margin-top:10px;')
                v-btn(color='primary' @click='saveRemindMessag')
                  | GUARDAR MENSAJE PERSONALIZADO
              v-text-field(v-model="searchEmail" append-icon="mdi-search" label= 'Buscar paciente' hide-details="" clearable name='searchBar' type='text')
              v-row(style='margin-top:15px;')
                v-data-table.mx-2#tablaDatos(v-model="selectedToEmailRemain" :headers='emailHeaders' :items="showEmailArr" show-select single-select=false item-key="names" :search="searchEmail" dense fixed-header :sort-by="orderBy" calculate-widths :footer-props="{\'items-per-page-text':'Pacientes por página'\}")
                  v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                    | Sin resultados
                  v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                    | No hay pacientes que requieran control semestral
                  v-flex(xs12='', sm10='', offset-sm1='')
                v-row(style='display:flex; justify-content:center;')
                  v-btn(color='primary' @click='sendRemindEmails' :loading='loadingRemindEmails')
                    | ENVIAR CORREOS
</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import emailjs from 'emailjs-com'
  export default {
    components: {
    },
    props: {
      allPatients: {
        default: null,
        type: Array,
      },
    },
    data: () => ({
      loadingRemindEmails: false,
      remindMessage: '',
      loadingSendBirthEmails: false,
      birthMessage: '',
      currentDrId: firebase.auth().currentUser.uid,
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      orderBy: null,
      searchBirth: '',
      searchEmail: '',
      showBirthArr: [],
      showEmailArr: [],
      selectedToBirth: [],
      selectedToEmailRemain: [],
      birthHeaders: [
        { text: 'Nombres', value: 'names', align: 'left', filterable: true },
        { text: 'Apellidos', value: 'lastNames', align: 'left', filterable: true },
        { text: 'Email', value: 'email', align: 'left', filterable: true },
        { text: 'Nacimiento', value: 'bornDate', align: 'left', filterable: true },
      ],
      emailHeaders: [
        { text: 'Nombres', value: 'names', align: 'left', filterable: true },
        { text: 'Apellidos', value: 'lastNames', align: 'left', filterable: true },
        { text: 'Email', value: 'email', align: 'left', filterable: true },
        { text: 'Última cita', value: 'dateLastApp', align: 'left', filterable: true },
      ],
    }),
    computed: {
      drName () {
        return this.$store.state.reminders.drName
      },
      sendedBirthArrLoaded () {
        return this.$store.state.reminders.birthSendedArr
      },
      sendedReminArrLoaded () {
        return this.$store.state.reminders.reminSendedArr
      },
    },
    watch: {
      sendedBirthArrLoaded () {
        this.constructBirthArray()
      },
      sendedReminArrLoaded () {
        this.constructRemindArray()
      },
    },
    created () {
      this.initMethod()
    },
    methods: {
      initMethod () {
        this.constructBirthArray()
        this.constructRemindArray()
        this.$store.dispatch('reminders/loadBirthMessag').then(res => {
          if (res.message) {
            this.birthMessage = res.message
          } else {
            this.birthMessage = 'Hola\nFeliz Cumpleaños!! Te desea tu odontólogo ' + this.drName + ', muchas felicidades!!\nPD: No olvide el aseo dental 3 veces al día y control de salud oral cada 6 meses.\nUn abrazo enorme\nTu odontólogo ' + this.drName
          }
        })
        this.$store.dispatch('reminders/loadRemindMessag').then(res => {
          if (res.message) {
            this.remindMessage = res.message
          } else {
            this.remindMessage = 'Hola\nSoy tu odontólogo ' + this.drName + ', para recordarte que ya es hora de una revisión dental, ya que se recomienda que se realice cada 6 meses. Saludos.\nUn abrazo enorme'
          }
        })
      },
      saveRemindMessag () {
        this.$store.dispatch('reminders/setRemindMessag', this.remindMessage).then(() => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Mensaje guardado correctamente',
          }
        }).catch(() => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar mensaje',
          }
        })
      },
      saveBirthMessag () {
        this.$store.dispatch('reminders/setBirthMessag', this.birthMessage).then(() => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Mensaje guardado correctamente',
          }
        }).catch(() => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar mensaje',
          }
        })
      },
      sendBirthEmails () {
        this.loadingSendBirthEmails = true
        if (this.selectedToBirth.length > 0) {
          for (let i = 0; i < this.selectedToBirth.length; i++) {
            if (this.selectedToBirth[i].email !== '') {
              var templateParams = {
                name: this.selectedToBirth[i].names,
                email: this.selectedToBirth[i].email,
                message: this.birthMessage,
              }
              // setTimeout(() => {
              emailjs.send('dentalprime_services', 'template_a48vkzh', templateParams,
                           'user_U9EdBXAW1WR0FQRbjBGY1').then(() => {
                             this.snackbar = {
                               show: true,
                               color: 'green',
                               text: 'Correos enviados con éxito',
                             }
                           })
              // this.selectedToBirth = []
              // this.loadingSendBirthEmails = false
              // }, 2000)
              const currentDate = new Date().toString().substr(4, 12)
              this.selectedToBirth[i].currentDate = currentDate
              if (this.sendedBirthArrLoaded.length > 0) {
                if (this.sendedBirthArrLoaded[0].data.sendDate === currentDate) {
                  this.$store.dispatch('reminders/pushBirthMailSended', this.selectedToBirth[i])
                } else {
                  this.$store.dispatch('reminders/setBirthMailSended', this.selectedToBirth[i])
                }
              } else {
                this.$store.dispatch('reminders/setBirthMailSended', this.selectedToBirth[i])
              }
            }
          }
          this.selectedToBirth = [] // para deseleccionar los checkboxes
          this.loadingSendBirthEmails = false
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'No hay correos que enviar',
          }
          this.loadingSendBirthEmails = false
        }
      },
      sendRemindEmails () {
        this.loadingRemindEmails = true
        if (this.selectedToEmailRemain.length > 0) {
          for (let i = 0; i < this.selectedToEmailRemain.length; i++) {
            if (this.selectedToEmailRemain[i].email !== '') {
              var templateParams = {
                name: this.selectedToEmailRemain[i].names,
                email: this.selectedToEmailRemain[i].email,
                message: this.remindMessage,
              }
              // setTimeout(() => {
              emailjs.send('dentalprime_services', 'template_1i1gsog', templateParams,
                           'user_U9EdBXAW1WR0FQRbjBGY1').then(() => {
                             this.snackbar = {
                               show: true,
                               color: 'green',
                               text: 'Correos enviados con éxito',
                             }
                           })
              //  this.selectedToEmailRemain = []
              // this.loadingRemindEmails = false
              // }, 2000)
              const currentDate = new Date().toString().substr(4, 12)
              this.selectedToEmailRemain[i].currentDate = currentDate
              if (this.sendedReminArrLoaded.length > 0) {
                if (this.sendedReminArrLoaded[0].data.sendDate === currentDate) {
                  this.$store.dispatch('reminders/pushReminMailSended', this.selectedToEmailRemain[i])
                } else {
                  this.$store.dispatch('reminders/setReminMailSended', this.selectedToEmailRemain[i])
                }
              } else {
                this.$store.dispatch('reminders/setReminMailSended', this.selectedToEmailRemain[i])
              }
            }
          }
          this.selectedToEmailRemain = [] // para deseleccionar los checkboxes
          this.loadingRemindEmails = false
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'No hay correos que enviar',
          }
          this.loadingRemindEmails = false
        }
      },
      constructBirthArray () {
        this.showBirthArr = []
        const currentDate = new Date().toString().substr(4, 6)
        const fullCurrentDate = new Date().toString().substr(4, 12)
        for (let i = 0; i < this.allPatients.length; i++) {
          const eachBornDate = new Date(this.allPatients[i].data.data.bornDate + 'T00:00:00').toString().substr(4, 6)
          const fullEachDate = new Date(this.allPatients[i].data.data.bornDate + 'T00:00:00').toString().substr(4, 12)
          // primero se valida que sean la misma fecha
          if (currentDate === eachBornDate) {
            if (this.sendedBirthArrLoaded.length > 0) {
              // se verifica si es que ya se le ha enviado un correo a ese paciente
              const emailIndex = this.sendedBirthArrLoaded.findIndex(mail => mail.data.email === this.allPatients[i].data.data.email)
              if (emailIndex !== -1) {
                // se verifica para que no se le pueda enviar otro correo al mismo paciente en la misma fecha
                if (this.sendedBirthArrLoaded[emailIndex].data.sendDate !== fullCurrentDate) {
                  this.showBirthArr.push({ names: this.allPatients[i].data.data.names, lastNames: this.allPatients[i].data.data.lastNames, email: this.allPatients[i].data.data.email, bornDate: fullEachDate })
                }
              } else {
                this.showBirthArr.push({ names: this.allPatients[i].data.data.names, lastNames: this.allPatients[i].data.data.lastNames, email: this.allPatients[i].data.data.email, bornDate: fullEachDate })
              }
            } else {
              this.showBirthArr.push({ names: this.allPatients[i].data.data.names, lastNames: this.allPatients[i].data.data.lastNames, email: this.allPatients[i].data.data.email, bornDate: fullEachDate })
            }
          }
        }
      },
      async constructRemindArray () {
        this.showEmailArr = []
        let appointmentArray = []
        const today = new Date().toString().substr(4, 12)
        await this.$store.dispatch('reminders/loadAppointArr').then(res => {
          appointmentArray = res
        })
        for (let i = 0; i < appointmentArray.length; i++) {
          const lastAppointmentDate = appointmentArray[i].patientAppoint[appointmentArray[i].patientAppoint.length - 1].appointmentData.currentDate
          const dateLastAppointment = new Date(lastAppointmentDate)
          var dateLastAppointmentAdd = dateLastAppointment.setMonth(dateLastAppointment.getMonth() + 6)
          dateLastAppointmentAdd = new Date(dateLastAppointmentAdd).toString().substr(4, 12)
          // primero se valida que sean la misma fecha
          if (dateLastAppointmentAdd === today) {
            if (this.sendedReminArrLoaded.length > 0) {
              const emailIndex = this.sendedReminArrLoaded.findIndex(mail => mail.data.email === this.allPatients[i].data.data.email)
              // se valida que aun no se haya enviado un correo a ese correo
              if (emailIndex !== -1) {
                // se verifica para que no se le pueda enviar otro correo al mismo paciente en la misma fecha
                if (this.sendedReminArrLoaded[emailIndex].data.sendDate !== today) {
                  await this.$store.dispatch('reminders/loadPatientData', appointmentArray[i].id).then(res => {
                    this.showEmailArr.push({ names: res.data.names, lastNames: res.data.lastNames, email: res.data.email, bornDate: res.data.bornDate, dateLastApp: lastAppointmentDate })
                  })
                }
              } else {
                // se arma el array de pacientes a los que se les debe enviar el correo
                await this.$store.dispatch('reminders/loadPatientData', appointmentArray[i].id).then(res => {
                  this.showEmailArr.push({ names: res.data.names, lastNames: res.data.lastNames, email: res.data.email, bornDate: res.data.bornDate, dateLastApp: lastAppointmentDate })
                })
              }
            } else {
              // se arma el array de pacientes a los que se les debe enviar el correo
              await this.$store.dispatch('reminders/loadPatientData', appointmentArray[i].id).then(res => {
                this.showEmailArr.push({ names: res.data.names, lastNames: res.data.lastNames, email: res.data.email, bornDate: res.data.bornDate, dateLastApp: lastAppointmentDate })
              })
            }
          }
        }
      },
    },
  }
</script>
<style>
.v-text-field__details{
  display:none
}
</style>
